@use "../config" as *;
.waf-fixtures {
  padding: 0;
  margin-bottom: 0;
  // .waf-head {
  //   height: 43rem;
  // }
  @extend %page-head-blue-bg;
  @extend %common-input-swiper-btn;
  @extend %tabs-underline;
  @extend %tab-section-common;
  .layout-wrapper {
    padding-bottom: var(--space-20);
  }
  .site-search-form {
    .search-input {
      border-width: 0.1rem;
      @extend %bg-neutral-0;
    }
  }
  .waf-head {
    .head-tab {
      @extend %d-none;
    }
  }
  .waf-body {
    border-radius: unset;
    background-color: unset;
  }
  .filter {
    &-options {
      @extend %p-10-4;
      @extend %border-radius-m;
      @extend %mb-8;
      @extend %bg-neutral-0;
      &-one {
        border-bottom: .1rem solid var(--neutral-20);
        @extend %px-4;
        @extend %mx-4-neg;
        @extend %mb-10;
        .swiper-button {
          &-prev {
            left: 0;
          }
          &-next {
            right: 0;
          }
        }
      }
    }
  }
  .card {
    &-item {
      width: auto;
      &.active {
        .card {
          &-anchor {
            border: none;
            @extend %bg-primary-50;
          }
          &-text {
            @extend %neutral-5;
          }
        }
      }
    }
    &-anchor {
      height: 4.8rem;
      border: .1rem solid var(--neutral-20);
      backdrop-filter: blur(.4rem);
      @extend %px-4;
      @extend %border-radius-s;
    }
    &-text {
      @extend %primary-50;
      @extend %text-mb;
    }
  }
  .tab-section {
    .fname,
    .lname {
      flex-basis: 13rem;
      .text {
        @include truncate-text(2);
      }
    }
    .nation,
    .start {
      flex-basis: 10rem;
    }
    .nation {
      .image {
        object-fit: cover;
        @include border(1, neutral-20, 10);
      }
    }
    .tpoints,
    .evscurr,
    .evsprev,
    .pointscurr,
    .points_prev {
      flex-basis: 12.2rem;
    }
    .event-name {
      flex-basis: 71.6rem;
    }
    .waf-accordion-body {
      .table {
        &-head {
          height: 7.3rem;
          @extend %bg-neutral-0;
          .text {
            font-weight: 700;
          }
        }
      }
    }
  }
  .table-body .time .text {
    font-weight: 700;
  }
  .table-option {
    &-list {
      padding-inline: var(--container-white-space);
      background: var(--secondary-40) linear-gradient(270deg, rgba(225, 239, 237, 0.00) -6.17%, #E1EFED 100%);
      @extend %py-4;
      .title {
        letter-spacing: -.03rem;
        @extend %title-60;
      }
    }
    &-item:not(:last-child) {
      @extend %mb-6;
    }
    &-wrap {
      border: .1rem solid var(--neutral-20);
      display: grid;
      backdrop-filter: blur(.4rem);
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      @extend %border-radius-s;
      @extend %bg-neutral-0;
    }
    &-meta {
      height: 7.5rem;
      align-content: space-between;
      gap: var(--space-2) var(--space-1);
      @extend %border-radius-s;
      @extend %p-2;
      @extend %flex;
      @extend %flex-wrap;
      &:not(:last-child) {
        border-right: .1rem solid var(--neutral-20);
      }
      &.temperature {
        .meta-number {
          width: max-content;
          &::after {
            content: '\00B0 C';
            @extend %body-lb;
          }
        }
      }
    }
  }
  .meta {
    &-condition {
      @include truncate-text(2);
      @extend %neutral-70;
      @extend %text-s;
    }
    &-number {
      flex-basis: 100%;
      order: 3;
      @extend %title-60;
    }
    &-tag {
      color: var(--neutral-100);
      order: 2;
      @extend %text-s;
    }
  }
  .table-footer-note {
    @extend %mt-14;
  }
  .note-list-item {
    &:not(:last-child) {
      @extend %mb-6;
    }
    .label,
    .text {
      @extend %d-block;
    }
    .label {
      font: 700 1.6rem/1.5 $font-primary;
      @extend %mb-2;
    }
    .text {
      white-space: pre-line;
      @extend %neutral-80;
      @extend %body-l;
    }
    .link {
      text-decoration: underline;
      @extend %primary-50;
      @extend %text-l;
    }
  }
  .card-banner {
    height: 38.3rem;
    background: url(/static-assets/images/cssimages/pattern/fourstar-results-m.png?v=#{$image-version}) center / cover no-repeat;
    @extend %w-100;
    @extend %px-3;
    @extend %border-radius-m;
    @extend %mb-12;
    @extend %flex-column-c-n;
    .btn-site {
      background-color: var(--neutral-0);
      color: var(--primary-50);
      @extend %mt-8;
      @extend %btn-fill;
      &:hover {
        color: var(--neutral-0);
      }
      .text {
        font-weight: 700;
      }
    }
    .card {
      &-thumbnail {
        @extend %mb-14;
      }
      &-content {
        @extend %neutral-5;
        .text {
          @extend %body-l;
        }
      }
    }
    .title {
      letter-spacing: -.064rem;
      @extend %title-30;
    }
    .thumb-logo {
      width: 57%;
      margin-left: auto;
    }
  }
  .live-card-list {
    @extend %mb-12;
    @extend %event-card-small;
  }
  .table-actions {
    .btn {
      &:hover::before {
        @extend %primary-55;
      }
      &:active::before {
        @extend %primary-85;
      }
      &:disabled::before {
        @extend %neutral-40;
      }
    }
  }
  .tab-container-info {
    padding: 6% var(--space-4);
    @extend %full-radius;
    @extend %bg-neutral-5;
    .info-title {
      @extend %mb-4;
      @extend %text-center;
    }
    .info-desc {
      @extend %d-block;
      @extend %neutral-70;
      @extend %text-center;
    }
  }
  .table-body {
    .fname,
    .lname {
      @extend %primary-40;
      &:hover {
        @extend %primary-70;
      }
    }
  }
}
.waf-fourstar-fixtures,
.waf-results {
  .layout-wrapper {
    padding-block: var(--space-12);
    @include large-wrapper();
  }
  .waf-head {
    aspect-ratio: unset;
    border-radius: unset;
    background: unset;
    min-height: unset;
    height: unset;
    &::before {
      content: unset;
    }
    .title {
      @extend %pb-8;
      @extend %neutral-100;
      @extend %title-40;
    }
  }
  .head-wrap {
    margin-block: 0;
  }
  .waf-body {
    margin-top: 0;
  }
}
@media screen and (min-width: $md-min-width),
print {
  .waf-fixtures {
    .layout-wrapper {
      padding-bottom: 9.6rem;
    }
    .filter-options {
      padding-top: var(--space-6);
      padding-inline: calc(var(--container-white-space) / 2);
      border-radius: var(--border-radius-l);
      &-one {
        padding-inline: calc(var(--container-white-space) / 2);
        margin-inline: calc(-1 * var(--container-white-space) / 2);
      }
      &::after {
        top: 8.8rem;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .card {
      &-list {
        gap: var(--space-2);
        overflow-x: auto;
        overflow-y: hidden;
      }
      &-item {
        min-width: 15rem;
        flex: 1;
      }
      &-anchor {
        width: 100%;
        height: 8rem;
      }
    }
    .tab-section {
      .fname,
      .lname {
        flex-basis: 11.19%;
        min-width: 13rem;
      }
      .pos {
        min-width: 5.5rem;
        flex-basis: 4.96%;
      }
      .nation {
        min-width: 10rem;
      }
      .start {
        min-width: 10rem;
        flex-basis: 9%;
      }
      .tpoints,
      .evscurr,
      .evsprev,
      .pointscurr,
      .points_prev {
        flex-basis: 8.8%;
        min-width: 10rem;
      }
      .swim,
      .t1,
      .bike,
      .t2,
      .run {
        min-width: 8rem;
      }
    }
    .table-option {
      &-list {
        padding: var(--space-10) calc(var(--container-white-space) / 2);
        background: url(/static-assets/images/cssimages/pattern/results-race-bg-d.png?v=#{$image-version});
        .title {
          margin-bottom: var(--space-6);
          font-size: 2rem;
          line-height: 1.4;
        }
      }
      &-item:not(:last-child) {
        margin-bottom: var(--space-10);
      }
      &-wrap {
        border: none;
        border-radius: 0;
        background-color: unset;
        gap: var(--space-6);
      }
      &-meta {
        height: unset;
        padding: var(--space-4);
        border: .1rem solid var(--neutral-20);
        background-color: var(--neutral-0);
        justify-content: space-between;
      }
    }
    .meta {
      &-condition {
        flex-basis: 100%;
        font-size: 1.4rem;
      }
      &-number {
        font-size: 1.8rem;
        flex-basis: unset;
        line-height: 1.6;
        order: 2;
      }
      &-tag {
        padding: var(--space-2) var(--space-3);
        border: .1rem solid var(--neutral-20);
        border-radius: 100vmax;
        font-weight: 700;
        order: 3;
        font-size: 1.4rem;
      }
    }
    .card-banner {
      height: auto;
      padding: var(--space-14);
      background-image: url(/static-assets/images/cssimages/pattern/fourstar-results-d.png?v=#{$image-version});
      flex-direction: row-reverse;
      gap: var(--space-4);
      justify-content: space-between;
      .thumb-logo {
        width: 22rem;
      }
      .title {
        font-size: 4rem;
        line-height: 1.2;
      }
      .btn-site {
        width: fit-content;
        margin-top: var(--space-6);
      }
    }
    .live {
      &-result-section {
        @include default-wrapper();
      }
      &-card-list {
        margin-bottom: var(--space-14);
        @include card-count(3, var(--space-4), wrap);
        @include custom-scroll();
        .card-item {
          height: 20rem;
          min-width: unset;
          border-radius: var(--border-radius-m);
          flex: unset;
          &:not(:last-child) {
            margin-bottom: 0;
          }
          &::before {
            width: unset;
            height: 23rem;
            right: unset;
            bottom: -9%;
            left: 35%;
            opacity: .2;
          }
          &::after {
            background: linear-gradient(180deg, hsl(var(--hsl-neutral-0) / 0) 0%, hsl(var(--hsl-neutral-0) / 1) 100%);
          }
        }
        .title {
          -webkit-line-clamp: 3;
          line-clamp: 3;
        }
        .text {
          margin-bottom: var(--space-2);
          display: inline-block;
          color: var(--primary-50);
          font-size: 1.6rem;
        }
      }
    }
  }
  .waf-fourstar-fixtures,
  .waf-results {
    .layout-wrapper {
      padding-block: var(--space-20);
    }
    .waf-head .title {
      padding-bottom: var(--space-14);
      font-size: 4rem;
      line-height: 1.2;
    }
  }
  .waf-fourstar-fixtures {
    .waf-head {
      min-height: unset;
    }
  }
}
@include mq(col-lg) {
  .waf-fourstar-fixtures,
  .waf-results .live-card-list {
    @include card-count(4, var(--space-6), wrap);
  }
  .waf-fixtures {
    .table-option {
      &-list {
        display: grid;
        gap: calc(var(--container-white-space) / 2);
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
      }
      &-item {
        @include flex-config(flex, column);
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
      &-wrap {
        flex-grow: 1;
      }
    }
    .tab-section {
      .table-header {
        position: relative;
        padding-inline: var(--space-24);
        justify-content: center;
        @include border(1, neutral-20, 10, bottom);
        .table-actions {
          transform: translateY(-50%);
          @include flex-config(flex, row, null, center);
          @include position(50%, var(--space-4));
        }
      }
      //added as per TRI team
      .tabs {
        position: relative;
        > .table-actions {
          transform: translateY(-50%);
          @include flex-config(flex, row, null, center);
          @include position(50%, var(--space-10));
        }
      }
    }
  }
}
@include mq(col-desktop) {
  .waf-fourstar-fixtures,
  .waf-results {
    .live-card-list {
      @include card-count(4, var(--space-6));
    }
  }
}
@media print and (orientation: portrait) {
  .waf-fourstar-fixtures {
    .tab-section {
      .table-data {
        flex-basis: 9.5%;
      }
      .pos {
        flex-basis: 4%;
      }
      .yob {
        flex-basis: 6.5%;
      }
      .start {
        flex-basis: 7%;
      }
      .nation {
        flex-basis: 7.5%;
      }
      .fname,
      .lname {
        flex-basis: 9%;
      }
    }
    .table-option-list {
      padding-inline: var(--space-6);
    }
    .text {
      font-size: 1.1rem !important;
    }
  }
}
@media print {
  @page {
    size: landscape
  }
  body {
    background-color: unset !important;
  }
  .body-wrap {
    padding-top: 0 !important;
  }
  .menu-wrap,
  .footer-wrapper {
    display: none;
  }
  #onetrust-consent-sdk,
  .secondary-header,
  .uwy,
  .snackbar-wrap {
    display: none !important;
  }
  .waf-fixtures {
    background-color: unset !important;
    .layout-wrapper {
      max-width: 90% !important;
      padding-block: 0 !important;
    }
    .waf-head,
    .waf-body {
      max-width: unset;
    }
    .waf-head {
      height: unset !important;
      background: unset;
      &::before {
        content: unset;
      }
      .head-wrap {
        max-width: 100%;
        margin-block: 0;
      }
      .title {
        padding-bottom: var(--space-5) !important;
        color: var(--neutral-100);
        font-size: 3rem !important;
      }
    }
    .table {
      overflow-wrap: anywhere;
      &-option-list {
        background: unset;
      }
      &-head,
      &-body,
      &-row {
        width: 100%;
      }
      &-row,
      &-option-wrap {
        page-break-inside: avoid;
      }
      &-data {
        min-width: unset !important;
      }
    }
    .filter-options {
      padding: 0;
      border-radius: 0;
      margin-top: 0;
      gap: var(--space-8);
      @include flex-config(flex, null, null, center);
      &-one,
      &-two {
        width: max-content;
        margin-inline: 0;
      }
      &-one {
        padding-inline: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
      &-two .card {
        &-item {
          width: max-content;
          min-width: none;
          flex: unset;
          &:not(.active) {
            display: none;
          }
          &.active .card {
            &-anchor {
              padding-inline: 0;
              border-radius: unset;
              background-color: unset;
              backdrop-filter: unset;
            }
            &-text {
              color: var(--primary-50);
            }
          }
        }
        &-anchor {
          width: unset;
          height: unset;
        }
        &-text {
          font-size: 2rem;
        }
      }
    }
    .tab {
      &-section {
        padding-top: 0;
        padding-inline: 0;
        border-radius: unset;
        background-color: unset;
        .tabs {
          display: none !important;
        }
        .table {
          &-header {
            height: 6.5rem;
            border-radius: unset;
            background-color: var(--neutral-10);
          }
          &-data {
            height: 6rem;
            padding-inline: .6rem;
          }
        }
      }
      &s {
        overflow: unset;
      }
      &-item {
        &:not(.active) {
          display: none;
        }
        &.active .tab {
          &-name {
            height: unset;
            &::before {
              content: unset;
            }
          }
          &-text {
            font-weight: 700;
          }
        }
      }
      &-name {
        padding-inline: 0;
      }
      &-text {
        font-size: 2rem;
      }
    }
    .waf-body {
      margin-top: 0;
    }
    .live-result-section,
    .form-wrapper,
    .table-actions,
    .table-data::after,
    .loadmore-wrap {
      display: none !important;
    }
    .text {
      font-size: 1.2rem !important;
    }
  }
  .waf-fourstar-fixtures, .waf-fivestar-fixtures {
    .tab-section {
      .table-data {
        flex-grow: 0 !important;
        flex-shrink: 1 !important;
      }
      .fname, .lname {
        flex-grow: 1 !important;
      }
    }
  }
  .waf-eventstrip-showcase {
    .showcase-wrapper {
      height: 25rem;
    }
    .article {
      &-thumbnail {
        display: none;
      }
      &-content {
        width: 90%;
        padding-inline: 0;
        display: block;
        top: 50%;
        text-align: left;
        transform: translateY(-50%);
      }
      &-title {
        width: 100%;
        margin-top: 0;
        font-size: 3.8rem;
      }
      &-meta {
        align-items: flex-start;
      }
    }
    .article-title,
    .meta {
      color: var(--neutral-100);
    }
    .article-logo,
    .social-list {
      display: none;
    }
    .meta-date::before {
      background-color: var(--neutral-100);
    }
  }
}