@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward "./showcase/showcase";
@forward './events/fourstar-shoswcase';
@forward "./fixtures/results";
@forward "./standings/common-ranking-results";
@use "./config/" as *;
.waf-fixtures {
    &.waf-results {
        height: unset;
    }
    &.waf-fivestar-fixtures {
        .waf-head {
            height: 35rem;
            margin-top: var(--secondary-header-height);
        }
        .waf-body {
            margin-top: var(--space-12-neg);
        }
    }
}
@include mq(col-lg) {
    .waf-fixtures {
        &.waf-results {
            height: unset;
        }
        &.waf-fivestar-fixtures {
            .waf-head {
                height: 37rem;
                margin-top: 0;
            }
        }
    }
}